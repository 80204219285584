import { Button } from 'amphitheatre';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import CallToAction from 'src/Components/CallToAction';
import Container from 'src/Components/ContentContainer';
import SEO from 'src/Components/SEO';
import Layout from 'src/Layout';
import * as Base from 'src/page-styles/base.styled';
import * as Hero from 'src/page-styles/hero.styled';
import styled from 'styled-components';

// const Certified = styled.div`
//   border: 0.25rem solid ${({ theme }) => theme.success600};
//   border-radius: 100%;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   height: 96px;
//   width: 96px;

//   svg {
//     color: ${({ theme }) => theme.success600};
//     height: 48px;
//     width: 48px;
//   }

//   @media only screen and (min-width: 768px) {
//     border: 0.5rem solid ${({ theme }) => theme.success600};
//     height: 200px;
//     width: 200px;

//     svg {
//       height: 128px;
//       width: 128px;
//     }
//   }

//   @media only screen and (min-width: 1024px) {
//     height: 256px;
//     width: 256px;
//   }
// `;

const Infrastructure = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
`;

const InfrastructureItem = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const CustomHeroText = styled(Hero.Text)`
  box-sizing: border-box;

  padding-right: 0px;
  position: relative;

  @media only screen and (min-width: 1024px) {
    padding-right: 352px;
  }

  @media only screen and (min-width: 1200px) {
    padding-right: 512px;
  }
`;

function SpaceCockpit(): JSX.Element {
  return (
    <>
      <SEO
        title="Space Cockpit | Saber Astronautics"
        description="A 3D space domain awareness tool, specifically designed for the warfighter at the tactical edge. Deployable on CLASSIFIED networks."
        url="https://www.saberastro.com/space-cockpit"
      />
      <Layout>
        <Hero.Wrapper>
          <Hero.Cover />
          <Hero.Cover />
          <StaticImage
            className="hero-image"
            src="../images/space-cockpit/space-cockpit.jpg"
            alt="hero"
          />
          <CustomHeroText>
            <Hero.MajorHeader style={{ textAlign: 'left' }}>
              Space Cockpit
            </Hero.MajorHeader>
            <Hero.Header style={{ textAlign: 'left', marginBottom: '1rem' }}>
              <Base.Yellow>
                A 3D space domain awareness tool, specifically designed for the
                warfighter at the tactical edge. Deployable on CLASSIFIED
                networks.
              </Base.Yellow>
            </Hero.Header>
            <Hero.Header style={{ textAlign: 'left' }}>
              <Link to="/contact-us" target="_blank" rel="noopener noreferrer">
                <Button size="xlg">Get in touch</Button>
              </Link>
            </Hero.Header>
            <Hero.LogoWrapper>
              <StaticImage
                className="logo"
                src="../images/space-cockpit/logo.png"
                alt="hero"
              />
            </Hero.LogoWrapper>
          </CustomHeroText>
        </Hero.Wrapper>
        <Container.Dark>
          <Base.Gap />
          <Base.Text>
            <Base.Header style={{ textAlign: 'center' }}>
              Easy-to-use tools providing operators with the context and
              information they need to make decisions quickly and accurately on
              the front line of space control
            </Base.Header>
          </Base.Text>
          <Base.Gap />
          <StaticImage
            src="../images/space-cockpit/space-cockpit-2.jpg"
            alt="SpaceCAMP"
          />
          <Base.Gap />
          <Base.Gap />
          <Infrastructure>
            <InfrastructureItem>
              <StaticImage
                src="../images/space-cockpit/spacecamp.png"
                height={258}
                alt="SpaceCAMP"
              />
            </InfrastructureItem>
            <InfrastructureItem>
              <StaticImage
                src="../images/space-cockpit/platformone.png"
                height={258}
                alt="Platform One"
              />
            </InfrastructureItem>
            <InfrastructureItem>
              <StaticImage
                src="../images/space-cockpit/logo.png"
                height={258}
                alt="Platform One"
              />
            </InfrastructureItem>
          </Infrastructure>
          <Base.Gap />
          <Base.Text>
            <Base.Paragraph>
              Deployed using PlatformOne. Space Cockpit uses the latest in
              DevSecOps initiatives and has a{' '}
              <b>current Certificate to Field (CtF).</b>
            </Base.Paragraph>
          </Base.Text>
          <Base.Gap />
          <Base.Paragraph style={{ textAlign: 'center' }}>
            Space Cockpit is deployable to
          </Base.Paragraph>
          <Base.Heading
            style={{
              background: `linear-gradient(180deg,#fec60a,#f18f01 90%)`,
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'rgba(0,0,0,0.2)',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Deployed to both Top-Secret and
            <br /> Secret classification levels.
          </Base.Heading>
        </Container.Dark>
        <CallToAction
          header="Interested in Space Cockpit?"
          linkText="Contact us"
          to="/contact-us/"
        />
      </Layout>
    </>
  );
}

export default SpaceCockpit;
